import React from 'react'

const DiversityBlockquote = ({ quote }) => {
  return (
    <blockquote
      className="diversity-report-thin-width-column d-section"
      dangerouslySetInnerHTML={{
        __html: quote,
      }}
    />
  )
}

export default DiversityBlockquote
