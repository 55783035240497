import React from 'react'

const DiversitySingleImage = ({ image }) => {
  return (
    <div className="d-section">
      <picture className="c-image c-image--fullscreen">
        {image.thumbnails.Mobile && (
          <source
            media="(max-width: 767px)"
            srcSet={image.thumbnails.Mobile.url}
          />
        )}
        <img src={image.url} alt={image.alt} />
      </picture>
    </div>
  )
}

export default DiversitySingleImage
