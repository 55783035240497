import React from 'react'

import TableRow from './diversity-table-row'

const DiversityTable = props => {
  return (
    <table>
      <thead>
        <tr>
          <th>Percentage</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((item, index) => {
          return (
            <TableRow
              key={index}
              percentage={item.data_point_percentage}
              description={item.data_point_description}
            />
          )
        })}
      </tbody>
    </table>
  )
}

export default DiversityTable
