import React from 'react'
import classnames from 'classnames'

const ImageGrid = ({ images, direction = 'Left', tall = false }) => {
  return (
    <div
      className={classnames(
        `c-image-grid`,
        `c-image-grid--${images.length}`,
        `c-image-grid--${images.length}--${direction}`,
        {
          [`c-image-grid--tall`]: tall,
        }
      )}
    >
      {images.map(({ image }, index) => (
        <div key={index} className="item">
          <img src={image.url} alt={image.alt} />
        </div>
      ))}
    </div>
  )
}

export default ImageGrid
