import React from 'react'

export default () => {
  return (
    <React.Fragment>
      <aside className="s-container--diversity diversity-report-thin-width-column d-footer__logo">
        <svg
          viewBox="0 0 150 46"
          width="150"
          height="46"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-labelledby="di-footer-title di-footer-desc"
        >
          <title id="di-footer-title">Versett + Diversity and Inclusion</title>
          <desc id="di-footer-desc">
            Versett works hard to make everyone feel like they belong.
          </desc>
          <path d="M102.268.336H90.914v45h11.354c12.54 0 22.708-10.075 22.708-22.5 0-12.426-10.168-22.5-22.708-22.5M126.931 45.336h22.709v-45H126.93zM.034 45.336v-45h45.44v45H.034zm33.898-29.899l-6.836 14.797h2.718l6.836-14.797h-2.718zm-6.099 0l-6.837 14.797h2.716l6.84-14.797h-2.719zm-15.878-.007H8.858l6.784 14.812h1.98l6.852-14.812h-3.097l-4.744 10.766-4.678-10.766zM73.287 21.49h-3.734V17.79h-2.718v3.699H63.1v2.691h3.735v3.699h2.718V24.18h3.734z" />
        </svg>
      </aside>
      <aside className="d-prefooter diversity-report-thin-width-column s-container--diversity">
        <h2>All Aboard</h2>
        <p>
          Versett is designed to be a place for doing meaningful work. Our team
          is tight-knit and dedicated to building great products. Send us
          something you’ve made that you’re proud of and we’ll be in touch.
        </p>
        <p>
          <a href="/careers">We're hiring</a>
        </p>
      </aside>
      <footer className="d-footer diversity-report-thin-width-column s-container--diversity">
        <p>
          <small>
            &copy; Versett {new Date().getFullYear()} - Diversity Report
          </small>
        </p>
      </footer>
    </React.Fragment>
  )
}
