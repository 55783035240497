import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="d-header s-container--diversity">
          <section className="d-header__top-bar">
            <div className="d-header__logo">
              <Link to="/">
                <svg
                  viewBox="0 0 436 236"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="21"
                  aria-labelledby="logo-versett"
                >
                  <title id="logo-versett">{this.props.siteTitle}</title>
                  <path d="M48.88.89l73.26 170.25L196.43.89h48.5L137.62 235.11h-31L.38.89h48.5zM297.54 1L190.47 235H233L340.11 1h-42.57zm95.51 0L286 235h42.56L435.62 1h-42.57z" />
                </svg>
              </Link>
            </div>
            <p>Report {this.props.reportYear}</p>
          </section>
        </header>
      </React.Fragment>
    )
  }
}
