import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'

export default class DiversityTableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      count: 0,
      done: false,
    }
  }
  animateValue = (end, start = 0, duration = 500) => {
    end = Math.floor(end)
    if (!this.state.done && end !== 0 && end !== null) {
      var range = end - start
      var current = start
      var increment = end > start ? 1 : -1
      var stepTime = Math.abs(Math.floor(duration / range))
      var timer = setInterval(() => {
        current += increment
        this.setState({ count: current })
        if (current === end) {
          this.setState({ done: true })
          this.setState({ count: this.props.percentage })
          clearInterval(timer)
        }
      }, stepTime)
    }
  }
  render() {
    return (
      <Waypoint
        bottomOffset="10%"
        onEnter={() => this.animateValue(this.props.percentage)}
      >
        <tr style={{ '--percentage': this.state.count }}>
          <td>{this.state.count}</td>
          <td>{this.props.description}</td>
        </tr>
      </Waypoint>
    )
  }
}
