import React, { Component } from 'react'

import Table from './diversity-table'

export default class DiversitySection extends Component {
  constructor(props) {
    super(props)

    const uid = Math.ceil(Math.random() * 1000000)

    this.state = {
      uid: uid,
      showCurrent: true,
      currentTab: `tab-${this.props.reportYear}-${uid}`,
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="diversity-report__section d-section"
          id={this.props.currentData.primary.section_name
            .toLowerCase()
            .replace(/ /g, '-')}
        >
          <h2 className="diversity-report__section__heading">
            <span className="diversity-report-thin-width-column">
              {this.props.currentData.primary.section_name}
            </span>
          </h2>
          <div
            className="diversity-report__section__body diversity-report-thin-width-column"
            dangerouslySetInnerHTML={{
              __html: this.props.currentData.primary.data_body.html,
            }}
          />

          <div className="diversity-report__section__data tabbed-interface diversity-report-thin-width-column">
            {this.props.previousData && (
              <React.Fragment>
                <div role="tablist" aria-orientation="horizontal">
                  <button
                    role="tab"
                    onClick={() => {
                      this.setState({
                        currentTab: `tab-${this.props.reportYear}-${this.state.uid}`,
                      })
                    }}
                    aria-selected={
                      this.state.currentTab ===
                      `tab-${this.props.reportYear}-${this.state.uid}`
                    }
                    id={`tab-${this.props.reportYear}-${this.state.uid}`}
                    aria-controls={`panel-${this.props.reportYear}-${this.state.uid}`}
                  >
                    {this.props.reportYear}
                  </button>
                  {this.props.previousData.map((year, index) => (
                    <button
                      key={index}
                      role="tab"
                      onClick={() => {
                        this.setState({
                          currentTab: `tab-${year.report_year}-${this.state.uid}`,
                        })
                      }}
                      aria-selected={
                        this.state.currentTab ===
                        `tab-${year.report_year}-${this.state.uid}`
                      }
                      id={`tab-${year.report_year}-${this.state.uid}`}
                      aria-controls={`panel-${year.report_year}-${this.state.uid}`}
                    >
                      {year.report_year}
                    </button>
                  ))}
                </div>
                {this.props.previousData.map((year, index) => (
                  <div
                    key={index}
                    id={`panel-${year.report_year}-${this.state.uid}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${year.report_year}-${this.state.uid}`}
                    style={{
                      display:
                        this.state.currentTab ===
                        `tab-${year.report_year}-${this.state.uid}`
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <Table items={year.data} />
                  </div>
                ))}
              </React.Fragment>
            )}
            <div
              id={`panel-${this.props.reportYear}-${this.state.uid}`}
              role="tabpanel"
              aria-labelledby={`tab-${this.props.reportYear}-${this.state.uid}`}
              style={{
                display:
                  this.state.currentTab ===
                  `tab-${this.props.reportYear}-${this.state.uid}`
                    ? 'block'
                    : 'none',
              }}
            >
              <Table items={this.props.currentData.items} />
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}
